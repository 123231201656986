import React, { useEffect, useRef, useState } from "react";
import PaymentForm from "../payment/PaymentForm";
import {
  ILotSetting,
  IPaymentGuests,
  IPaymentItem,
  IPayments,
  IRoomRates,
} from "../../models/payment/PaymentInterface";
import {
  Box,
  Grid,
  Stack,
  Typography,
  TextField,
  useTheme,
  Modal,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  styled,
  Select,
  MenuItem,
  Button,
  ButtonBase,
  IconButton,
  Backdrop,
} from "@mui/material";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import { AxiosError } from "axios";
import BASE_API from "../../constants/api";
import Notification from "../../utils/notificationConfig";
import axiosInstance from "../../constants/axiosConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import theme from "../../assets/theme/theme";
import { Check, Close } from "@mui/icons-material";
import { transactionCategoryEnum } from "../../constants/enums";
import { IMerch } from "../../types/merch";
import { extractNumbersFromString } from "../../utils/functions";
import { RoomBooking } from "../../pages/booking/interface";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55%",
  bgcolor: "black",
  border: 2,
  borderColor: theme.palette.primary.main,
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
};

type Props = {
  isAddItemModalOpen: boolean;
  handleAddItemModalOnClose?: () => void;
  paymentItemsProp: IPaymentItem[];
  guestsListProp: IPaymentGuests;
  bookingId: string;
  latestBookingStatus: string;
  roomBooking: RoomBooking[];
};

const StyledRadio = styled(Radio)(() => ({
  color: "white",
}));

enum itemType {
  merch = "Merch",
  shower = "Shower",
  locker = "Locker",
  adjustment = "Adjustment",
}

interface ILockerRate {
  lockerRateId: string;
  hoursOfUsage: number;
  lockerRate: number;
  isLatest: boolean;
}

interface IShowerRate {
  showerRateId: string;
  hoursOfUsage: number;
  showerRate: number;
  isLatest: boolean;
}

const AddItemToPOAModal = ({
  isAddItemModalOpen,
  handleAddItemModalOnClose,
  paymentItemsProp,
  guestsListProp,
  bookingId,
  latestBookingStatus,
  roomBooking,
}: Props) => {
  const theme = useTheme();
  const accountId = useSelector((state: RootState) => state.user.accountId);
  const token = useSelector((state: RootState) => state.user.accessToken);
  const lotId = useSelector((state: RootState) => state.user.lotId);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingTransactions, setIsLoadingTransactions] =
    useState<boolean>(false);
  const [isLoadingMerch, setIsLoadingMerch] = useState<boolean>(false);
  const [isLoadingShowerRate, setIsLoadingShowerRate] =
    useState<boolean>(false);
  const [isLoadingSettings, setIsLoadingSettings] = useState<boolean>(false);
  const [isLoadingRoomRates, setIsLoadingRoomRates] = useState<boolean>(false);

  const [isPaymentFormLoading, setIsPaymentFormLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      !isLoadingTransactions &&
      !isLoadingMerch &&
      !isLoadingShowerRate &&
      !isLoadingSettings &&
      !isLoadingRoomRates &&
      !isPaymentFormLoading
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [
    isLoadingMerch,
    isLoadingRoomRates,
    isLoadingSettings,
    isLoadingShowerRate,
    isLoadingTransactions,
    isPaymentFormLoading,
  ]);

  const handleSetPaymentFormReady = (value: boolean) => {
    setIsPaymentFormLoading(!value);
  };

  const skuInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    setAddedItem([]);

    if (isAddItemModalOpen) {
      timeoutId = setTimeout(() => {
        if (skuInputRef.current) {
          skuInputRef.current.focus();
        }
      }, 100);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isAddItemModalOpen]);

  const [adjustmentModalOpen, setAdjustmentModalOpen] =
    useState<boolean>(false);

  const handleOpenAdjustmentModal = () => {
    setAdjustmentModalOpen(true);
  };
  const handleCloseAdjustmentModal = () => {
    setAdjustmentModalOpen(false);
  };

  const [selectedItemType, setSelectedItemType] = useState<string>(
    itemType.merch
  );

  const [selectedDuration, setSelectedDuration] = useState<number>(0);

  const [amountOfHoursAdded, setAmountOfHoursAdded] = useState<number>(1);

  const [lockerRates, setLockerRates] = useState<ILockerRate[]>([]);
  const [showerRates, setShowerRates] = useState<IShowerRate[]>([]);
  const [adjustments, setAdjustments] = useState<ILotSetting[]>([]);

  const [selectedAdjustment, setSelectedAdjustment] = useState<ILotSetting>({
    lot: 0,
    settingsCategory: "",
    settingsDescription: "",
    settingsId: "",
    settingsName: "",
  });

  const [paymentItems, setPaymentItems] =
    useState<IPaymentItem[]>(paymentItemsProp);
  const handlePayItemsOnChange = (paymentItems: IPaymentItem[]) => {
    setPaymentItems(paymentItems);
  };

  const [guestsList, setGuestsList] = useState<IPaymentGuests>(guestsListProp);
  const handleGuestListOnChange = (guestsList: IPaymentGuests) => {
    setGuestsList(guestsList);
  };

  useEffect(() => {
    handlePayItemsOnChange(paymentItemsProp);
  }, [paymentItemsProp]);

  useEffect(() => {
    handleGuestListOnChange(guestsListProp);
  }, [guestsListProp]);

  const [newItemToAdd, setNewItemToAdd] = useState<IPaymentItem[]>([]);

  // This state is for checking stock quantity
  const [addedItem, setAddedItem] = useState<IPaymentItem[]>([]);

  const [itemSku, setItemSku] = useState<string>("");
  const hanldeItemSkuOnChange = (itemSku: string) => {
    setItemSku(itemSku);
  };

  useEffect(() => {
    if (!isAddItemModalOpen) {
      setNewItemToAdd([]);
    }
  }, [isAddItemModalOpen]);

  const [transactions, setTransactions] = useState<IPayments[]>([]);

  useEffect(() => {
    const fetchTransactions = () => {
      setIsLoadingTransactions(true);
      axiosInstance
        .get(`/transaction/get-by-booking-id/${bookingId}/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0) {
              const dataTransaction = res.data.map((data: any) => ({
                transactionId: data.transactionId,
                invoiceNo: data.invoiceNo,
                transactionStatus: data.transactionStatus,
                sum: parseFloat(data.sum),
                amountPaid: data.debitAmount,
                status: data.transactionStatus,
                paymentType: data.paymentType,
                customer: data.customer,
                transactionDatetime: data.transactionDatetime,
                creditAmount: data.creditAmount,
                debitAmount: data.debitAmount,
                items: data.items,
                isRoomBooking: data.isRoomBooking,
              }));
              setTransactions(dataTransaction);
            }
          }
        })
        .catch((e) => Notification.failed(e.response.data.msg))
        .finally(() => setIsLoadingTransactions(false));
    };

    fetchTransactions();
  }, [bookingId]);

  useEffect(() => {
    setIsLoadingMerch(true);
    axiosInstance
      .get(`/merch/`, {
        params: {
          no_archived: true,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res: any) => {
        const data = res.data.merchs.filter(
          (data: IMerch) => data.itemDetails === "Locker"
        );
        const lockerRateData: ILockerRate[] = data.map((data: any) => ({
          lockerRateId: data.merchId,
          tiers: "",
          hoursOfUsage: extractNumbersFromString(data.itemName)[0],
          lockerRate: parseFloat(data.sum),
          isLatest: true,
          lot: lotId,
        }));

        const transformedLockerRates = lockerRateData.map((rate: any) => ({
          ...rate,
          lockerRate: parseFloat(rate.lockerRate),
        }));

        const sortedLockerRates = transformedLockerRates.sort(
          (a: ILockerRate, b: ILockerRate) => a.hoursOfUsage - b.hoursOfUsage
        );

        setLockerRates(sortedLockerRates);
      })
      .catch((err: AxiosError) => {
        Notification.failed(err.message);
      })
      .finally(() => setIsLoadingMerch(false));

    setIsLoadingShowerRate(true);
    axiosInstance
      .get(`/shower-rate`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res: any) => {
        const showerRateData = res.data.filter(
          (data: IShowerRate) => data.isLatest !== false
        );
        showerRateData.forEach((rate: any) => {
          rate.showerRate = parseFloat(rate.showerRate);
        });
        setShowerRates(showerRateData);
      })
      .catch((err: AxiosError) => {
        Notification.failed(err.message);
      })
      .finally(() => setIsLoadingShowerRate(false));

    setIsLoadingSettings(true);
    axiosInstance
      .get(`/lot-settings/get-settings/${accountId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAdjustments(
          res.data.data.filter(
            (data: ILotSetting) =>
              data.settingsCategory === "Payment Adjustments"
          )
        );
      })
      .finally(() => setIsLoadingSettings(false));
  }, []);

  const [roomRates, setRoomRates] = useState<IRoomRates[]>([]);
  //Get Rates
  useEffect(() => {
    const fetchRates = () => {
      setIsLoadingRoomRates(true);
      const apiUrlParams = new URLSearchParams();
      apiUrlParams.append("withAccountsLot", "true");
      apiUrlParams.append("specificPlatformTierBookingId", bookingId);
      axiosInstance
        .get(`/rooms/rate/?${apiUrlParams}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const updatedRoomRates = res.data.map((rate: any) => ({
            ...rate,
            roomRate: parseFloat(rate.roomRate),
          }));

          setRoomRates(updatedRoomRates);
        })
        .finally(() => setIsLoadingRoomRates(false));
    };
    fetchRates();
  }, []);

  const fetchItemBySku = async () => {
    if (!guestsList.customerStayingId) {
      Notification.warning("Dude, the guest is not assigned");
      return;
    }
    axiosInstance
      .get(
        `${BASE_API}/merch/?sku=${itemSku.toUpperCase()}&no_archived=${true}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res: any) => {
        if (res.data.merchs.length > 0) {
          if (checkStockAvailability(res.data.merchs[0], addedItem)) {
            Notification.success(res.data.message);

            const convertedData = {
              itemId: res.data.merchs[0].merchId,
              itemName: res.data.merchs[0].itemName,
              itemType: "Merch",
              category: transactionCategoryEnum.merchSales,
              quantity: 1,
              price: parseFloat(res.data.merchs[0].sum),
            };

            setNewItemToAdd([convertedData]);

            setAddedItem((prevValue) => {
              const findData = prevValue.find(
                (item) => item.itemId === res.data.merchs[0].merchId
              );
              if (findData) {
                const filteredData = prevValue.filter(
                  (item) => item.itemId !== res.data.merchs[0].merchId
                );
                return [
                  ...filteredData,
                  {
                    ...findData,
                    quantity: findData.quantity ? findData.quantity + 1 : 1,
                  },
                ];
              }
              return [...prevValue, convertedData];
            });
          }
        } else {
          Notification.failed("No Merch with this SKU");
        }
      })
      .catch((err: AxiosError) => {
        Notification.failed(err.message);
      });
  };

  function checkStockAvailability(item: IMerch, addedItem: IPaymentItem[]) {
    const { stockInQuantity, stockOutQuantity, itemName, merchId } = item;
    const merchAdded = addedItem.find((item) => item.itemId === merchId);

    if (stockInQuantity <= stockOutQuantity) {
      Notification.warning(`No more stock for ${itemName}`);
      return false;
    }

    if (
      merchAdded &&
      merchAdded.quantity &&
      stockInQuantity <= stockOutQuantity + merchAdded.quantity
    ) {
      Notification.warning(`No more stock for ${itemName}`);
      return false;
    }

    return true;
  }

  const handleChangeItemType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedItemType((event.target as HTMLInputElement).value);
  };

  const handleAddLockertoPOA = () => {
    setNewItemToAdd([
      {
        itemId: lockerRates[selectedDuration].lockerRateId,
        itemName: `Locker ${lockerRates[selectedDuration].hoursOfUsage} hours`,
        itemType: "Locker",
        category: transactionCategoryEnum.lockerSales,
        price: lockerRates[selectedDuration].lockerRate,
        quantity: 1,
        duration: lockerRates[selectedDuration].hoursOfUsage,
      },
    ]);
  };

  const handleAddShowertoPOA = () => {
    setNewItemToAdd([
      {
        itemId: showerRates[selectedDuration].showerRateId,
        itemName: `Shower ${showerRates[selectedDuration].hoursOfUsage} hours`,
        itemType: "Shower",
        category: transactionCategoryEnum.showerSales,
        price: showerRates[selectedDuration].showerRate,
        quantity: 1,
        duration: showerRates[selectedDuration].hoursOfUsage,
      },
    ]);
  };

  const getRoomBookingItem = () => {
    const roomName = roomBooking.map((room) => room.roomCode);
    const items = transactions
      .filter((transaction) => transaction.isRoomBooking)
      .flatMap((transaction) =>
        transaction.items.filter((item) => roomName.includes(item.itemName))
      );

    return items;
  };

  // const handleAddAdjustmenttoPOA = (price: string) => {
  //   if (selectedAdjustment.settingsName === "Add 1 hour") {
  //     const roomBookingItems = getRoomBookingItem();
  //     if (!roomBookingItems[0].duration) {
  //       Notification.failed("Unable to find duration in previous transaction");
  //       return;
  //     }
  //     if (price === "Add Ons") {
  //       setNewItemToAdd([
  //         ...roomBookingItems.map((item) => ({
  //           itemId: selectedAdjustment.settingsId,
  //           itemName: item.itemName,
  //           itemType: selectedAdjustment.settingsName,
  //           category: transactionCategoryEnum.adjustmentSales,
  //           quantity: amountOfHoursAdded,
  //           price: parseFloat(selectedAdjustment.settingsDescription),
  //           duration: amountOfHoursAdded,
  //         })),
  //       ]);
  //       return;
  //     }
  //     if (price === "Original") {
  //       if (
  //         roomBookingItems[0].duration &&
  //         [3, 6, 12, 24].includes(roomBookingItems[0].duration) &&
  //         amountOfHoursAdded === roomBookingItems[0].duration
  //       ) {
  //         setNewItemToAdd(
  //           roomBookingItems.map((item) => ({
  //             itemId: selectedAdjustment.settingsId,
  //             itemName: item.itemName,
  //             itemType: `${item.duration} hrs Extend`,
  //             category: transactionCategoryEnum.adjustmentSales,
  //             price: item.price,
  //             duration: item.duration,
  //           }))
  //         );
  //         return;
  //       }
  //       if (
  //         roomBookingItems[0].duration &&
  //         [3, 6, 12, 24].includes(amountOfHoursAdded)
  //       ) {
  //         const itemTypeArray: string[] = roomBookingItems
  //           .map((item) => item.itemType)
  //           .flat();
  //         const prices = roomRates.filter(
  //           (rate) =>
  //             itemTypeArray.includes(rate.roomTypeName) &&
  //             rate.hoursOfStay === amountOfHoursAdded
  //         );
  //         const newItem = roomBookingItems.map((paymentItem) => {
  //           const matchingRoomRate = prices.find(
  //             (rate) =>
  //               rate.roomTypeName === paymentItem.itemType &&
  //               rate.hoursOfStay === amountOfHoursAdded
  //           );

  //           if (matchingRoomRate) {
  //             // If a matching room rate is found, update the price in the payment item
  //             return {
  //               ...paymentItem,
  //               itemType: `${amountOfHoursAdded} hrs Extend`,
  //               price: matchingRoomRate.roomRate,
  //               duration: amountOfHoursAdded,
  //             };
  //           }

  //           // If no matching room rate is found, return the original payment item
  //           return paymentItem;
  //         });
  //         setNewItemToAdd(newItem);
  //         return;
  //       }
  //       if (roomBookingItems[0].duration && roomBookingItems[0].duration > 2) {
  //         setNewItemToAdd([
  //           ...roomBookingItems.map((item) => ({
  //             itemId: selectedAdjustment.settingsId,
  //             itemName: item.itemName,
  //             itemType: `${item.duration} hrs Extend`,
  //             category: transactionCategoryEnum.adjustmentSales,
  //             price: item.price,
  //             duration: item.duration,
  //           })),
  //           ...roomBookingItems.map((item) => ({
  //             itemId: selectedAdjustment.settingsId,
  //             itemName: item.itemName,
  //             itemType: selectedAdjustment.settingsName,
  //             category: transactionCategoryEnum.adjustmentSales,
  //             quantity: amountOfHoursAdded - roomBookingItems[0].duration!,
  //             price: parseFloat(selectedAdjustment.settingsDescription),
  //             duration: amountOfHoursAdded - roomBookingItems[0].duration!,
  //           })),
  //         ]);
  //         return;
  //       }
  //     }
  //   }
  //   if (price === "Updated") {
  //     if (parseFloat(selectedAdjustment.settingsDescription) <= 0) {
  //       Notification.failed("Please ensure that Adjustment is above 0");
  //       return;
  //     }
  //     setNewItemToAdd([
  //       {
  //         itemId: selectedAdjustment.settingsId,
  //         itemName: selectedAdjustment.settingsName,
  //         itemType: "Adjustment",
  //         category: transactionCategoryEnum.adjustmentSales,
  //         price: parseFloat(selectedAdjustment.settingsDescription),
  //       },
  //     ]);
  //   }
  // };

  const handleAddAdjustmenttoPOA = (price: string) => {
    const roomName = roomBooking.map((room) => room.roomCode);

    if (selectedAdjustment.settingsName === "Add 1 hour") {
      const roomBookingItems = getRoomBookingItem();
      const itemTypeArray: string[] = roomBookingItems
        .map((item) => item.itemType)
        .flat();
      const prices = roomRates.filter(
        (rate) =>
          itemTypeArray.includes(rate.roomTypeName) &&
          rate.hoursOfStay === amountOfHoursAdded
      );

      if (!roomBookingItems[0].duration) {
        Notification.failed("Unable to find duration in previous transaction");
        return;
      }
      if (price === "Add Ons") {
        setNewItemToAdd([
          ...roomBookingItems.map((item) => ({
            itemId: selectedAdjustment.settingsId,
            itemName: item.itemName,
            itemType: selectedAdjustment.settingsName,
            category: transactionCategoryEnum.adjustmentSales,
            quantity: amountOfHoursAdded,
            price: parseFloat(selectedAdjustment.settingsDescription),
            duration: amountOfHoursAdded,
          })),
        ]);
        return;
      }
      if (price === "Original") {
        if (
          roomBookingItems[0].duration &&
          [3, 6, 12, 24].includes(roomBookingItems[0].duration) &&
          amountOfHoursAdded === roomBookingItems[0].duration
        ) {
          transactions.flatMap((transaction) =>
            transaction.items.filter((item) => roomName.includes(item.itemName))
          );
          console.log(
            roomBookingItems.map((item) => ({
              itemId: selectedAdjustment.settingsId,
              itemName: item.itemName,
              itemType: `${item.duration} hrs Extend`,
              category: transactionCategoryEnum.adjustmentSales,
              price: item.price,
              duration: item.duration,
            }))
          );
          setNewItemToAdd(
            roomBookingItems.map((item) => {
              let itemPrice = item.price;

              if (itemPrice === 0) {
                const matchingRoomRate = prices.find(
                  (rate) =>
                    rate.roomTypeName === item.itemType &&
                    rate.hoursOfStay === amountOfHoursAdded
                );
                itemPrice = matchingRoomRate ? matchingRoomRate.roomRate : 0;
              }
              return {
                itemId: selectedAdjustment.settingsId,
                itemName: item.itemName,
                itemType: `${item.duration} hrs Extend`,
                category: transactionCategoryEnum.adjustmentSales,
                price: itemPrice,
                duration: item.duration,
              };
            })
          );
          return;
        }
        if (
          roomBookingItems[0].duration &&
          [3, 6, 12, 24].includes(amountOfHoursAdded)
        ) {
          const newItem = roomBookingItems.map((paymentItem) => {
            const matchingRoomRate = prices.find(
              (rate) =>
                rate.roomTypeName === paymentItem.itemType &&
                rate.hoursOfStay === amountOfHoursAdded
            );

            if (matchingRoomRate) {
              // If a matching room rate is found, update the price in the payment item
              return {
                ...paymentItem,
                itemType: `${amountOfHoursAdded} hrs Extend`,
                price: matchingRoomRate.roomRate,
                duration: amountOfHoursAdded,
              };
            }

            // If no matching room rate is found, return the original payment item
            return paymentItem;
          });
          setNewItemToAdd(newItem);
          return;
        }
        if (roomBookingItems[0].duration && roomBookingItems[0].duration > 2) {
          setNewItemToAdd([
            ...roomBookingItems.map((item) => ({
              itemId: selectedAdjustment.settingsId,
              itemName: item.itemName,
              itemType: `${item.duration} hrs Extend`,
              category: transactionCategoryEnum.adjustmentSales,
              price: item.price,
              duration: item.duration,
            })),
            ...roomBookingItems.map((item) => ({
              itemId: selectedAdjustment.settingsId,
              itemName: item.itemName,
              itemType: selectedAdjustment.settingsName,
              category: transactionCategoryEnum.adjustmentSales,
              quantity: amountOfHoursAdded - roomBookingItems[0].duration!,
              price: parseFloat(selectedAdjustment.settingsDescription),
              duration: amountOfHoursAdded - roomBookingItems[0].duration!,
            })),
          ]);
          return;
        }
      }
    }
    if (price === "Updated") {
      if (parseFloat(selectedAdjustment.settingsDescription) <= 0) {
        Notification.failed("Please ensure that Adjustment is above 0");
        return;
      }
      setNewItemToAdd([
        {
          itemId: selectedAdjustment.settingsId,
          itemName: selectedAdjustment.settingsName,
          itemType: "Adjustment",
          category: transactionCategoryEnum.adjustmentSales,
          price: parseFloat(selectedAdjustment.settingsDescription),
        },
      ]);
    }
  };

  return (
    <Modal
      open={isAddItemModalOpen}
      onClose={handleAddItemModalOnClose || (() => {})}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
        },
      }}
    >
      <Box
        width={"70%"}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Grid
          container
          spacing={0}
          padding={0}
          sx={{
            backgroundColor: "black",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              variant={"h2"}
              paddingY={1}
              paddingX={2}
              border={2}
              borderColor={theme.palette.primary.main}
              borderBottom={0}
            >
              Add Item
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              sx={{
                height: "63vh",
                overflowY: "auto",
              }}
            >
              <PaymentForm
                paymentItem={paymentItems}
                getPastTransactions={true}
                bookingId={bookingId}
                bookingGuest={guestsList}
                width={"100%"}
                margin={"0"}
                height={"100%"}
                newItem={newItemToAdd}
                isExpressBooking={false}
                latestBookingStatus={latestBookingStatus}
                handleSetPaymentFormReady={handleSetPaymentFormReady}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              height={"200px"}
              justifyContent={"center"}
              alignItems={"center"}
              bgcolor={theme.palette.background.default}
              borderLeft={2}
              borderRight={2}
              borderBottom={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack
                direction={"row"}
                width={"100%"}
                justifyContent={"space-around"}
              >
                <Box
                  display={"flex"}
                  width={"48%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  bgcolor={theme.palette.background.paper}
                  borderBottom={2}
                  borderColor={theme.palette.primary.main}
                >
                  <RssFeedIcon sx={{ fontSize: "30px" }} />
                  <Typography variant="h4" fontSize={"24px"} marginLeft={1}>
                    Scan QR Code on Scanner
                  </Typography>
                </Box>
                <Stack
                  direction={"column"}
                  width={"48%"}
                  paddingX={2}
                  paddingY={1}
                  bgcolor={theme.palette.background.paper}
                  spacing={1}
                >
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    alignItems={"center"}
                    spacing={4}
                  >
                    <Typography variant="h4" width={"160px"}>
                      Item Type
                    </Typography>
                    <FormControl sx={{ width: "450px" }}>
                      <RadioGroup
                        row
                        aria-labelledby="type-radio-buttons-group-label"
                        name="type-radio-buttons-group"
                        value={selectedItemType}
                        onChange={handleChangeItemType}
                        sx={{
                          justifyContent: "space-between",
                        }}
                      >
                        <FormControlLabel
                          value={itemType.merch}
                          control={<StyledRadio />}
                          label="Merch"
                        />
                        <FormControlLabel
                          value={itemType.locker}
                          control={<StyledRadio />}
                          label="Locker"
                        />
                        {/* <FormControlLabel
                        value={itemType.shower}
                        control={<StyledRadio />}
                        label="Shower"
                      /> */}
                        <FormControlLabel
                          value={itemType.adjustment}
                          control={<StyledRadio />}
                          label="Adjustment"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                  <Stack alignItems={"center"} direction={"row"} borderTop={0}>
                    {selectedItemType === itemType.merch ? (
                      <Stack
                        direction={"row"}
                        width={"100%"}
                        alignItems={"center"}
                        spacing={4}
                      >
                        <Typography variant={"h4"} width={"160px"}>
                          SKU
                        </Typography>
                        <Stack
                          direction={"row"}
                          height={"40px"}
                          width={"100%"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <TextField
                            size="small"
                            value={itemSku}
                            onChange={(e) =>
                              hanldeItemSkuOnChange(e.target.value)
                            }
                            variant="outlined"
                            fullWidth
                            inputRef={skuInputRef}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                fetchItemBySku();
                              }
                            }}
                          />
                          <Button
                            variant="outlined"
                            onClick={() => fetchItemBySku()}
                            disabled={isLoading}
                            sx={{ borderRadius: 1, color: "white" }}
                          >
                            Add
                          </Button>
                        </Stack>
                      </Stack>
                    ) : selectedItemType === itemType.locker ? (
                      <Stack
                        direction={"row"}
                        width={"100%"}
                        alignItems={"center"}
                        spacing={4}
                      >
                        <Typography variant={"h4"} width={"160px"}>
                          Duration (hrs)
                        </Typography>
                        <Stack
                          direction={"row"}
                          height={"40px"}
                          width={"100%"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <Stack
                            direction={"row"}
                            width={"100%"}
                            height={"100%"}
                            alignItems={"center"}
                            spacing={1}
                          >
                            {lockerRates.map((locker, index) => (
                              <ButtonBase
                                onClick={() => setSelectedDuration(index)}
                                sx={{
                                  width: "33.3%",
                                  height: "100%",
                                  bgcolor:
                                    selectedDuration === index
                                      ? theme.palette.primary.main
                                      : theme.palette.background.default,
                                  color:
                                    selectedDuration === index
                                      ? "black"
                                      : "white",
                                  borderRadius: 1,
                                }}
                              >
                                {locker.hoursOfUsage}
                              </ButtonBase>
                            ))}
                          </Stack>
                          <Button
                            variant="outlined"
                            onClick={() => handleAddLockertoPOA()}
                            disabled={isLoading}
                            sx={{ borderRadius: 1, color: "white" }}
                          >
                            Add
                          </Button>
                        </Stack>
                      </Stack>
                    ) : selectedItemType === itemType.shower ? (
                      <Stack
                        direction={"row"}
                        spacing={2}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Typography variant="h3">Shower Duration: </Typography>
                        <FormControl variant="standard">
                          <Select
                            value={selectedDuration}
                            onChange={(event: any) =>
                              setSelectedDuration(event.target.value)
                            }
                          >
                            {showerRates.map((rate, index) => (
                              <MenuItem key={index} value={index}>
                                {rate.hoursOfUsage} hours (MYR {rate.showerRate}
                                )
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Button
                          variant="outlined"
                          onClick={handleAddShowertoPOA}
                          disabled={isLoading}
                        >
                          Add
                        </Button>
                      </Stack>
                    ) : (
                      <Stack direction={"column"} width={"100%"} spacing={1}>
                        <Stack
                          direction={"row"}
                          width={"100%"}
                          alignItems={"center"}
                          spacing={4}
                        >
                          <Typography variant="h4" width={"160px"}>
                            Item Name
                          </Typography>
                          <Select
                            size="small"
                            value={selectedAdjustment?.settingsId}
                            onChange={(event) => {
                              setSelectedAdjustment(
                                adjustments.filter(
                                  (adjustment) =>
                                    adjustment.settingsId === event.target.value
                                )[0]
                              );
                            }}
                            sx={{
                              width:
                                selectedAdjustment.settingsName !==
                                "Updated Price"
                                  ? "50%"
                                  : "100%",
                            }}
                          >
                            {adjustments.map((adjustment) => (
                              <MenuItem
                                key={adjustment.settingsId}
                                value={adjustment.settingsId}
                              >
                                {adjustment.settingsName}
                              </MenuItem>
                            ))}
                          </Select>
                          {selectedAdjustment.settingsName !==
                            "Updated Price" && (
                            <>
                              <TextField
                                type="number"
                                size="small"
                                value={amountOfHoursAdded}
                                onChange={(event) =>
                                  setAmountOfHoursAdded(
                                    parseInt(event.target.value)
                                  )
                                }
                                inputProps={{ min: 1 }}
                                sx={{ width: "90px" }}
                              />
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  if (amountOfHoursAdded < 1) {
                                    Notification.warning(
                                      "Make sure amount of hours added is more than 0"
                                    );
                                    return;
                                  }
                                  const roomBookingItems = getRoomBookingItem();
                                  const firstRoomBookingItem =
                                    roomBookingItems.length > 0
                                      ? roomBookingItems[0]
                                      : null;
                                  const hasDuration =
                                    firstRoomBookingItem &&
                                    firstRoomBookingItem.duration;
                                  if (
                                    [3, 6, 12, 24].includes(
                                      amountOfHoursAdded
                                    ) ||
                                    (roomBookingItems.length > 0 &&
                                      hasDuration &&
                                      amountOfHoursAdded >=
                                        firstRoomBookingItem.duration!)
                                  ) {
                                    handleOpenAdjustmentModal();
                                  } else {
                                    handleAddAdjustmenttoPOA("Add Ons");
                                  }
                                }}
                                disabled={
                                  isLoading ||
                                  selectedAdjustment.settingsId === ""
                                }
                                sx={{ borderRadius: 1, color: "white" }}
                              >
                                Add
                              </Button>
                            </>
                          )}
                        </Stack>
                        {selectedAdjustment.settingsName ===
                          "Updated Price" && (
                          <Stack
                            direction={"row"}
                            height={"40px"}
                            width={"100%"}
                            alignItems={"center"}
                            spacing={4}
                          >
                            <Typography variant="h4" width={"160px"}>
                              Amount (MYR)
                            </Typography>
                            <Stack direction={"row"} width={"100%"} spacing={1}>
                              <TextField
                                type="number"
                                size="small"
                                fullWidth
                                value={selectedAdjustment?.settingsDescription}
                                onChange={(event) =>
                                  setSelectedAdjustment({
                                    ...selectedAdjustment,
                                    settingsDescription: event.target.value,
                                  })
                                }
                              />
                              <Button
                                variant="outlined"
                                onClick={() =>
                                  handleAddAdjustmenttoPOA("Updated")
                                }
                                disabled={
                                  isLoading ||
                                  selectedAdjustment.settingsId === ""
                                }
                                sx={{ borderRadius: 1, color: "white" }}
                              >
                                Add
                              </Button>
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <Modal
          open={adjustmentModalOpen}
          onClose={handleCloseAdjustmentModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
              },
            },
          }}
        >
          <Box sx={modalStyle}>
            <Stack
              height={"2.5rem"}
              direction={"row"}
              alignItems={"center"}
              paddingX={"1rem"}
              justifyContent={"space-between"}
              borderBottom={2}
              borderColor={theme.palette.primary.main}
              sx={{ background: "#232323" }}
            >
              <Typography
                variant={"h3"}
                color={theme.palette.primary.main}
                fontWeight={"700"}
                fontSize={"1.1rem"}
              >
                Scan Card
              </Typography>
              <IconButton onClick={handleCloseAdjustmentModal}>
                <HighlightOffIcon
                  fontSize={"medium"}
                  sx={{ color: theme.palette.primary.main }}
                />
              </IconButton>
            </Stack>
            <>
              <Stack
                width={"100%"}
                height={"150px"}
                justifyContent={"center"}
                spacing={2}
                paddingX={"30px"}
              >
                <Box width={"300px"} height={"40px"} padding={"1px"}>
                  <Stack
                    direction={"row"}
                    height={"100%"}
                    width={"100%"}
                    spacing={1}
                  >
                    <Typography variant="h4">
                      Do you want to use Original Rates?
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
              <Stack
                direction={"row"}
                width={"100%"}
                height={"110px"}
                sx={{ backgroundColor: theme.palette.background.default }}
              >
                <ButtonBase
                  onClick={() => {
                    handleAddAdjustmenttoPOA("Add Ons");
                    handleCloseAdjustmentModal();
                  }}
                  sx={{
                    width: "50%",
                    height: "100%",
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                    height={"100%"}
                    borderTop={1}
                    borderRight={1}
                    borderColor={theme.palette.primary.main}
                    paddingX={1}
                  >
                    <Close sx={{ fontSize: "50px" }} />
                    <Typography variant="h4">No, Use Add Ons Price</Typography>
                  </Box>
                </ButtonBase>
                <ButtonBase
                  onClick={() => {
                    handleAddAdjustmenttoPOA("Original");
                    handleCloseAdjustmentModal();
                  }}
                  sx={{
                    width: "50%",
                    height: "100%",
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                    height={"100%"}
                    borderTop={1}
                    borderColor={theme.palette.primary.main}
                    paddingX={1}
                  >
                    <Check sx={{ fontSize: "50px" }} />
                    <Typography variant="h4">
                      Yes, Use Original Price
                    </Typography>
                  </Box>
                </ButtonBase>
              </Stack>
            </>
          </Box>
        </Modal>
      </Box>
    </Modal>
  );
};

export default AddItemToPOAModal;
